























import {
  Component,
  Inject as VueInject,
  Mixins, Prop,
  PropSync,
  Vue,
  Watch
} from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import {
  defaultProvider,
  Inject,
  IS_MOBILE_PROVIDER_KEY,
  UI_MODAL_OPENED_EVENT_KEY
} from '../../../../support'
import { SearchGroup, SearchResultConfig, SearchResultsConfig } from '../../../../contexts'
import { ComponentsStructureConfig, StructureConfigurable } from '../../../../support/mixins'

import { RouteNames } from '../../../root/routes'

import { ISearchService, SearchServiceType } from '../../services/search'
import { Loader } from '../../molecules/Loader'
import { SearchResultsDesktop, SearchResultsMobile } from './partials'

import { DEFAULT_COMPONENT_CONFIG, SEARCH_RESULTS_COMPONENT_KEY } from './SearchResults.config'

/**
 * Component capable top render `SearchResults` element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SearchResults>({
  name: 'SearchResults',
  components: { Loader },
  created (): void {
    this.componentConfig = this.getComponentConfig(
      SEARCH_RESULTS_COMPONENT_KEY,
      { ...DEFAULT_COMPONENT_CONFIG }
    )
  },
  mounted (): void {
    this.calculateOffset()
    this.registerListener()
  },
  destroyed (): void {
    this.removeListener()
    this.$emit('onDestroy')
  }
})
export class SearchResults extends Mixins(Vue, StructureConfigurable) {
  @PropSync('isOpen', { type: Boolean, required: true })
  public _isOpen!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly showDynamicResults!: boolean

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SearchServiceType)
  protected readonly searchService!: ISearchService

  public results: SearchGroup[] = []

  public isLoading: boolean = false

  public componentConfig: ComponentsStructureConfig = {}

  public popoverOffset: number = 0

  public get searchConfig (): SearchResultsConfig {
    return this.searchService.getConfig()
  }

  /**
   * Determines the initial data for search.
   */
  public get initialData (): SearchGroup {
    return this.searchService.getInitialData()
  }

  /**
   * Determines the state of the search results container.
   */
  public searchText: string = ''

  public get component (): VueConstructor {
    if (this.isMobile()) {
      return SearchResultsMobile
    }
    return SearchResultsDesktop
  }

  /**
   * Matches config with results and returns ready array.
   */
  public get elementsByGroups (): any {
    const internalConfigCopy = { ...this.searchConfig }

    // Remove key for initialData, so it should not intercourse with other keys.
    delete internalConfigCopy[this.initialData.group]

    return Object.keys(internalConfigCopy).map((e: string) => {
      const result = this.results.find((el: SearchGroup) => el.group === e)

      return {
        ...internalConfigCopy[e],
        ...result
      }
    })
  }

  /**
   * Determines whether search input has button.
   */
  public get searchInputHasButton (): boolean {
    return this.getConfigProperty(
      'searchInputHasButton',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.searchInputHasButton
    )
  }

  public get sliceResults (): number {
    return this.getConfigProperty(
      'sliceResults',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.sliceResults
    )
  }

  public get shouldWatchResize (): boolean {
    return this.getConfigProperty(
      'shouldWatchResize',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.shouldWatchResize
    )
  }

  /**
   * Determines whether component with search results should have position set
   */
  public get shouldSetResultsPosition (): boolean {
    return this.getConfigProperty(
      'shouldSetResultsPosition',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.shouldSetResultsPosition
    )
  }

  public get shouldRelyOnIsOpen (): boolean {
    return this.getConfigProperty(
      'shouldRelyOnIsOpen',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.shouldRelyOnIsOpen
    )
  }

  /**
   * Determines whether the input should have search close icon
   */
  public get hasCloseIcon (): boolean {
    return this.getConfigProperty(
      'hasCloseIcon',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.hasCloseIcon
    )
  }

  /**
   * Determines whether search input has hints.
   */
  public get hasSearchHints (): boolean {
    return this.getConfigProperty(
      'hasSearchHints',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.hasSearchHints
    ) && this.showDynamicResults
  }

  public get calculateOffsetForNavbar (): boolean {
    return this.getConfigProperty(
      'calculateOffsetForNavbar',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.calculateOffsetForNavbar
    )
  }

  /**
   * Determines search input placeholder.
   */
  public get inputPlaceholder (): string {
    return String(this.$t('front.root.views.SearchResults.inputPlaceholder'))
  }

  /**
   * Determines whether search input should collapse.
   */
  public get searchInputClosable (): boolean {
    return this.getConfigProperty(
      'searchInputClosable',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.searchInputClosable
    )
  }

  /**
   * Matches and checks config with initial elements.
   */
  public get initialElements (): SearchGroup & SearchResultConfig {
    if (!(Object.keys(this.searchConfig)).includes(this.initialData.group)) {
      throw new Error('Cannot find matching results key in config!')
    }

    return {
      ...this.searchConfig[this.initialData.group],
      ...this.initialData
    }
  }

  public get hasResults (): boolean {
    return Object.values(this.elementsByGroups).every((config: any) => {
      return Array.isArray(config.elements) && config.elements.length > 0
    })
  }

  public handleBodyClick (e: Event): void {
    if (!this._isOpen) {
      return
    }

    const target = (e.target as HTMLElement)
    const TOOLTIP_CONTAINER_SELECTOR = '.tooltip-inner'
    const searchResultsContainer = document.querySelector(TOOLTIP_CONTAINER_SELECTOR)
    if (searchResultsContainer?.contains(target)) {
      setTimeout(() => {
        this._isOpen = true
      }, 0)
    }
  }

  public handleSearchInputSubmit (): void {
    if (!this.searchInputHasButton) {
      return
    }

    setTimeout(() => {
      this.$router.push({ name: `root.${RouteNames.SearchResults}`, query: { q: this.searchText } })
    }, 400)
  }

  /**
   * Handles @cancel event of `SearchInput`
   */
  public onCancel (): void {
    this.$emit('updateOpen', false)
    this._isOpen = false
  }

  public onFocus () {
    this.$emit('updateOpen', true)
  }

  /**
   * Handles @input event of `SearchInput`
   */
  public onInput (value: string): void {
    this.searchText = value
  }

  /**
   * Determines whether search input is open on mount.
   */
  public get openOnMount (): boolean {
    return this.getConfigProperty(
      'searchInputOpenOnStart',
      this.componentConfig,
      DEFAULT_COMPONENT_CONFIG.searchInputOpenOnStart
    )
  }

  public registerListener (): void {
    document.body.addEventListener('click', this.handleBodyClick)

    this.eventBus.handle(UI_MODAL_OPENED_EVENT_KEY, () => {
      this._isOpen = false
    })
  }

  public removeListener (): void {
    document.body.removeEventListener('click', this.handleBodyClick)
  }

  /**
   * Emits search query.
   */
  @Watch('searchText')
  protected onSearch (value: string, oldValue: string): void {
    if (value === oldValue) return

    this.isLoading = true
    this.searchService.search(value, { perPage: 3 }).then((response) => {
      this.results = response
    }).finally(() => {
      this.isLoading = false
    })
  }

  protected calculateOffset (): void {
    this.popoverOffset = Number(window.document.documentElement.style.getPropertyValue('--body-margin-top')) > 0 ? Number(window.document.documentElement.style.getPropertyValue('--body-margin-top')) : 173
  }
}

export default SearchResults
