var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Navbar__mobile"},[(_vm.shouldHideMobileNav && !_vm.isPageWithoutMobileNav && _vm.logo && _vm.logo.image)?_c('div',{ref:"navigation",staticClass:"brand-wrapper",class:{'--withTopbar': _vm.hasTopBar }},[_c('UiLink',{attrs:{"target":_vm.logo.link ? _vm.logo.link.target : '',"isDisabled":_vm.logo.link && !_vm.logo.link.target}},[(_vm.logo.image)?_c('UiImage',_vm._b({},'UiImage',_vm.logo.image,false)):_vm._e()],1)],1):_vm._e(),(_vm.mainLinks)?_c('div',{staticClass:"sidebar-menu",class:{ '--open': _vm.isMenuOpen}},[(_vm.withOpenSearchType)?_c('div',{staticClass:"brand-wrapper"},[(_vm.logo)?_c('UiLink',{attrs:{"target":_vm.logo.link ? _vm.logo.link.target : '',"isDisabled":_vm.logo.link && !_vm.logo.link.target}},[(_vm.logo.image)?_c('UiImage',_vm._b({},'UiImage',_vm.logo.image,false)):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.withOpenSearchType)?_c('div',{staticClass:"search",class:{ '--backdrop': _vm.searchResultsOpen }},[_c('div',{staticClass:"container"},[_c('SearchResults',_vm._b({on:{"onDestroy":_vm.onDestroy,"updateOpen":function (value) { return _vm.searchResultsOpen = value; }}},'SearchResults',{ showDynamicResults: _vm.showDynamicResults, isOpen: _vm.searchResultsOpen },false))],1)]):_vm._e(),_c('UiNav',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var element = ref.element;
var index = ref.index;
return [_c('div',{staticClass:"container"},[_c('label',{staticClass:"sidebar-item",attrs:{"for":'open-menu-' + index},on:{"click":function($event){_vm.hasChildren(element) ? null : _vm.hideBackdrop}}},[_c(_vm.hasChildren(element)?'UiParagraph':'UiLink',_vm._b({tag:"component",staticClass:"sidebar-item__link"},'component',{ target: element.target, label: element.label, tabTarget:element.tabTarget },false),[_vm._v(" "+_vm._s(element.label)+" "),(element.icon)?_c('UiIcon',{attrs:{"width":"12","height":"16","name":element.icon}}):_vm._e()],1),(_vm.hasChildren(element))?[_c('label',{attrs:{"for":'open-menu-' + index}},[_c('UiIcon',{attrs:{"width":"22","height":"22","name":"SimpleArrowRight"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.menuOpen),expression:"menuOpen"}],attrs:{"type":"checkbox","id":'open-menu-' + index,"name":'open-menu-' + index,"true-value":index},domProps:{"checked":Array.isArray(_vm.menuOpen)?_vm._i(_vm.menuOpen,null)>-1:_vm._q(_vm.menuOpen,index)},on:{"change":function($event){var $$a=_vm.menuOpen,$$el=$event.target,$$c=$$el.checked?(index):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.menuOpen=$$a.concat([$$v]))}else{$$i>-1&&(_vm.menuOpen=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.menuOpen=$$c}}}}),_c('div',{staticClass:"sidebar-item-menu"},[_c('div',{staticClass:"nested-item-info"},[_c('label',{attrs:{"for":'open-menu-' + index}}),_c('span',[_vm._v(" "+_vm._s(element.label)+" ")])]),(_vm.menuOpen === index)?_vm._t("submenu",null,null,{ link: element }):_vm._e()],2)]:_vm._e()],2)])]}}],null,true)},'UiNav',{ links: _vm.mainLinks },false)),(_vm.hasExternalLinks)?_c('UiNav',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var element = ref.element;
return [_c('div',{staticClass:"container"},[_c('UiLink',_vm._b({staticClass:"sidebar-item__link"},'UiLink',{ target: element.target, label: element.label, tabTarget: element.tabTarget },false),[_vm._v(" "+_vm._s(element.label)+" ")])],1)]}}],null,false,1220424523)},'UiNav',{ links: _vm.externalLinks },false)):_vm._e(),(_vm.$slots.infobar)?_c('div',{staticClass:"infobar"},[_c('div',{staticClass:"container"},[_vm._t("infobar")],2)]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"search-container",class:{ '--open': _vm.selectedMenuItem === 'search' }},[_c('SearchResults',_vm._b({attrs:{"isOpen":_vm.isSearchOpen},on:{"update:isOpen":function($event){_vm.isSearchOpen=$event},"update:is-open":function($event){_vm.isSearchOpen=$event},"onDestroy":_vm.onDestroy}},'SearchResults',{ showDynamicResults: _vm.showDynamicResults },false))],1),_c('div',{ref:"mobileMenuWrapper",staticClass:"menu-wrapper"},[_c('UiList',_vm._b({staticClass:"menu-wrapper-list",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var element = ref.element;
return [_c('button',{staticClass:"menu-item",attrs:{"id":'menu-item-' + element.id,"disabled":_vm.isWaitingForAuth && _vm.accountLinkId===element.id},on:{"click":function($event){return _vm.onMenuItemSelect(element.id)}}},[(_vm.isWaitingForAuth && _vm.accountLinkId===element.id)?_c('Loader'):_c('UiIcon',{attrs:{"name":_vm.activeIcon(element)}}),(_vm.showBadge(element))?_c('span',{class:["item-badge", ("item-badge-" + (element.id))]},[_vm._v(_vm._s(_vm.constructBadge(element)))]):_vm._e(),_c('small',{staticClass:"label menu-item-text"},[_vm._v(" "+_vm._s(_vm.$t(("modules.Navbar.menuLabels." + (element.label))))+" ")])],1)]}}])},'UiList',{ elements: _vm.constructBottomNav() },false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }