
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { NavbarLogo, NavbarProps } from '../Navbar.contracts'
import { Inject } from '../../../../../support'
import { ISiteService, SiteServiceType } from '../../../../../contexts'
import { AuthMixin, IAuthMixin } from '../../../../auth/shared'
import { StructureConfigurable } from '../../../../../support/mixins'
import { toImageProps } from '../../../support'
import { ImageProps } from '../../../../../dsl/atoms/Image'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<LogoMixin>({
  name: 'LogoMixin'
})
export class LogoMixin extends Mixins<IAuthMixin, StructureConfigurable>(AuthMixin,
  StructureConfigurable) {
  @Prop({ type: Object, required: false })
  public readonly brandImage?: NavbarProps['brandImage']

  @Prop({ type: Object, required: false })
  public readonly logoLink?: NavbarProps['logoLink']

  @Prop({ type: Object, required: false })
  public readonly secondaryBrandImage?: NavbarProps['secondaryBrandImage']

  @Prop({ type: Object, required: false })
  public readonly secondaryLogoLink?: NavbarProps['secondaryLogoLink']

  @Inject(SiteServiceType, false)
  public readonly siteService?: ISiteService

  public get logo (): NavbarLogo | undefined {
    let image: ImageProps | undefined

    if (this.isUserPremium && this.shouldSwitchLogoWhenPremium) {
      if (this.secondaryBrandImage) {
        image = toImageProps({
          ...this.secondaryBrandImage,
          isLazy: false,
          alt: this.siteService?.getActiveSite().name || ''
        })
      }
    }

    if (!image) {
      if (this.brandImage) {
        image = toImageProps({
          ...this.brandImage,
          isLazy: false,
          alt: this.siteService?.getActiveSite().name || ''
        })
      }
    }

    if (!image) {
      return
    }

    return {
      image,
      link: this.isUserPremium ? this.secondaryLogoLink : this.logoLink
    }
  }

  /**
   * Determines whether component should switch logo when user is premium
   */
  public get shouldSwitchLogoWhenPremium (): boolean {
    return this.getConfigProperty('shouldSwitchLogoWhenPremium')
  }
}

export default LogoMixin
